<template>
  <div class="news">
    <div class="banner">
      <swiper
        ref="swiperRef"
        :options="{
          slidesPerView: isMobile ? 1 : 3,
          grabCursor: true,
          spaceBetween: 50,
        }"
        class="swiper-banners"
        @slideChange="bannerChange"
      >
        <swiper-slide v-for="item in newsBanner" :key="item.id">
          <div class="banner-item" @click="openwindow(item.websiteUrl)">
            <img class="banner-img" :src="item.image" />
            <div class="banner-content">
              <div class="banner-p">
                <p class="time">
                  {{ item.yeaer }}
                  <span>{{ item.mounty + "." + item.today }}</span>
                </p>
                <p class="title">
                  {{ item.headline }}
                </p>
                <p class="describe" v-if="item.subheadline">
                  {{ item.subheadline }}
                </p>
              </div>
              <div
                class="banner-filter"
                :style="{ backgroundImage: `url(${item.image})` }"
              ></div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="banner-swiper-btn prev">
        <img
          class="left"
          src="@/static/images/zuo.png"
          alt=""
          @click="swiperBanner('prev')"
        />
      </div>
      <div class="banner-swiper-btn next">
        <img
          class="right"
          src="@/static/images/qieh.png"
          alt=""
          @click="swiperBanner('next')"
        />
      </div>
    </div>
    <div class="global-title">
      <svg-icon iconClass="news" className="news-icon"></svg-icon>
      <span class="text">{{ $t("news") }}</span>
      <div class="page-icons">
        <div :class="['page-icon', { disabled: !leftImg }]" @click="onOnePiece">
          <svg-icon iconClass="page-prev"></svg-icon>
        </div>
        <div
          :class="['page-icon', { disabled: !rightImg }]"
          @click="nextPage"
          v-if="rightImg"
        >
          <svg-icon iconClass="page-next"></svg-icon>
        </div>
      </div>
    </div>
    <el-carousel
      indicator-position="none"
      :autoplay="false"
      arrow="never"
      ref="carousel"
      :initial-index="currot"
      :loop="false"
    >
      <el-carousel-item v-for="item in totalData" :key="item.id">
        <div class="list-page">
          <template v-for="init in item">
            <div
              class="box1"
              :key="init.id"
              @click="openwindow(init.websiteUrl)"
            >
              <img :src="init.image" alt="" />
              <div class="continaer">
                <p class="time">
                  {{ init.yeaer }}
                  <span>{{ init.mounty + "." + init.today }}</span>
                </p>
                <p class="title">
                  {{ init.headline }}
                </p>
                <p class="describe" v-if="init.subheadline">
                  {{ init.subheadline }}
                </p>
              </div>
            </div>
          </template>
        </div>
      </el-carousel-item>
    </el-carousel>
    <!-- <img class="right" src="@/static/images/qieh.png" alt="" @click="nextPage" v-if="rightImg"> -->
  </div>
</template>

<script>
import { newslist } from "@/api/require";
import { openUrl } from "@/utils";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      pageSize: 8,
      pageIndex: 2,
      totalData: [],
      newsBanner: [],
      bannerCrount: 0,
      currot: 0,
      shutoff: true, //是否允许请求
      leftImg: false, //返回
      rightImg: true, //前进
    };
  },
  computed: {
    ...mapGetters(["isMobile"]),
    swiper() {
      return this.$refs.swiperRef.$swiper;
    },
  },
  created() {
    this.getNewsBanner();
    this.newslist();
  },
  methods: {
    openwindow(url) {
      openUrl(url);
    },

    swiperBanner(type) {
      console.log(this.swiper);
      if (type == "next") {
        this.swiper.slideNext();
      } else {
        this.swiper.slidePrev();
      }
    },

    timeDisposal(val) {
      let time = val.split(" ")[0];
      return {
        yeaer: time.split("-")[0],
        mounty: time.split("-")[1],
        today: time.split("-")[2],
      };
    },
    async getNewsBanner() {
      const res = await newslist("", 1, 8);
      if (res.code === 0) {
        res.data.list.forEach((element) => {
          let time = this.timeDisposal(element.publishDate);
          element.yeaer = time.yeaer;
          element.mounty = time.mounty;
          element.today = time.today;
        });

        this.newsBanner = res.data.list;
      }
    },

    async newslist(flag) {
      if (this.shutoff) {
        let res = await newslist("", this.pageIndex, this.pageSize);
        if (res.code === 0) {
          if (res.data.list.length > 0) {
            res.data.list.forEach((element) => {
              let time = this.timeDisposal(element.publishDate);
              element.yeaer = time.yeaer;
              element.mounty = time.mounty;
              element.today = time.today;
            });

            this.totalData.push(res.data.list);

            if (flag == 1) {
              // 下一页
              this.leftImg = true;
              this.$forceUpdate();
              setTimeout(() => {
                this.$refs.carousel.next();
                this.$forceUpdate();
                this.currot = this.pageIndex - 1;
              }, 100);
            }

            if (res.data.list.length < 8) {
              // 数据不够了
              flag = false; //不允许翻页
              this.shutoff = false;
              this.rightImg = false; //隐藏前进按钮
            }
          } else {
            // 数据不够了
            flag = false; //不允许翻页
            this.shutoff = false;
            this.rightImg = false; //隐藏前进按钮
          }
        } else {
        }
      }
    },
    //   上一张
    onOnePiece() {
      if (this.pageIndex > 1) {
        this.shutoff = true;
        this.pageIndex -= 1;
        this.currot = this.pageIndex - 1;
        if (this.pageIndex == 1) {
          this.leftImg = false;
          this.rightImg = true;
        }
        this.$refs.carousel.prev();
        // this.newslist(2)
      } else {
        this.rightImg = true;
        this.leftImg = false;
      }
    },
    // 下一张
    async nextPage() {
      if (this.shutoff) {
        this.pageIndex += 1;

        this.newslist(1);
      }
    },

    bannerChange({ realIndex }) {
      this.bannerCrount = realIndex;
    },
  },
};
</script>

<style lang="less" scoped>
.news {
  height: 100%;
  .swiper-container {
    width: 100%;
    border-radius: 10px;
    .banner-img {
      width: 100%;
      border-radius: 30px;
      cursor: pointer;
      z-index: 1;
    }

    .banner-item {
      position: relative;

      &:hover .banner-img {
        transition: transform 0.3s ease-in-out;
        transform: scale(1.1);
      }
      &:hover .banner-filter {
        transform: scale(1.5);
      }
      .banner-filter {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-repeat: no-repeat;
        background-position: right bottom;
        background-size: cover;
        transform: scale(1.2);
        filter: blur(10px);
        background-color: rgba(34, 37, 44, 0.6);
        background-blend-mode: color;
      }

      .banner-content {
        position: absolute;
        bottom: 20px;
        right: 20px;
        width: 50%;
        z-index: 2;
        overflow: hidden;
        border-radius: 40px;
      }

      .banner-p {
        padding: 20px 30px 30px;

        .time {
          font-size: 14px;
          margin: 10px 0;
        }

        .title {
          margin: 10px 0;
          color: #f0f0f0;
          font-size: 16px;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .describe {
          font-size: 14px;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 6;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
  }
  .banner {
    position: relative;
    width: 100%;
    margin: 22px 0;
    .banner-swiper-btn {
      border-radius: 30px;
      width: 200px;
      height: 100%;
      z-index: 4;
      opacity: 0.8;
      background-color: rgba(0, 0, 0, 0);
      img {
        width: 100px;
        height: 100px;
      }
      transition: all 0.3s;

      &:hover {
        opacity: 1;
      }
    }

    .next {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.001) 0%,
        rgba(0, 0, 0, 0.5) 30%,
        rgba(0, 0, 0, 1) 100%
      );
    }
    .prev {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-image: linear-gradient(
        to left,
        rgba(0, 0, 0, 0.001) 0%,
        rgba(0, 0, 0, 0.5) 30%,
        rgba(0, 0, 0, 1) 100%
      );
    }

    .swiper-container,
    .swiper-container .banner-img {
      height: 320px;
    }
  }

  .global-title {
    font-size: 20px;
    color: #f0f0f0;
    margin: 30px 0;
    display: flex;
    align-items: center;

    .svg-icon {
      color: #848891;
      font-size: 20px;
    }
    .text {
      margin: 0 10px;
    }
    .page-icons {
      display: flex;
    }
    .page-icon {
      width: 22px;
      height: 22px;
      margin: 0 10px;
      border-radius: 50%;
      background: #2c2f37;

      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:not(.disabled):hover .svg-icon {
        color: #f0f0f0;
      }
    }

    .news-icon {
      color: #1edfa6;
    }
  }
  .left {
    width: 70px;
    height: 70px;
    cursor: pointer;
  }
  .right {
    width: 70px;
    height: 70px;
    cursor: pointer;
  }
  /deep/.el-carousel {
    width: 100%;
    height: 900px;
    .el-carousel__container {
      height: 100%;
    }
  }
}
.list-page {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  box-sizing: border-box;
  .box1 {
    cursor: pointer;
    overflow: hidden;
    width: 24%;
    // height: 128px;
    background: linear-gradient(0deg, #2d3138, #3a3f45);
    border-radius: 20px;
    // display: flex;
    margin-bottom: 20px;

    &:hover {
      img {
        transition: transform 0.3s ease-in-out;
        transform: scale(1.1);
      }
    }
    img {
      width: 100%;
      height: 230px;
      object-fit: cover;
      border-radius: 20px 20px 0 0;
    }
    .continaer {
      padding: 10px 20px 30px 20px;
      height: 110px;

      .describe {
        margin-top: 8px;
        font-size: 12px;
        font-weight: 400;
        color: #848891;
        overflow: hidden;
        text-overflow: ellipsis; // text-overflow css3的属性，当文本溢出时，显示省略号

        display: -webkit-box;

        -webkit-line-clamp: 2; // 设置两行文字溢出

        -webkit-box-orient: vertical;

        overflow: hidden;
      }
      .title {
        margin-top: 8px;
        font-size: 16px;
        font-weight: bold;
        color: #f0f0f0;
        overflow: hidden;
        text-overflow: ellipsis;

        display: -webkit-box;

        -webkit-line-clamp: 2; // 设置两行文字溢出

        -webkit-box-orient: vertical;
      }
      .time {
        font-size: 12px;
        color: #5e646e;
        margin: 10px 0;
      }
    }
  }
}
.isMobile {
  .el-carousel__item {
    overflow: auto !important;
  }
  .list-page .box1 {
    width: 100%;
  }
}
</style>
